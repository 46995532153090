const attendanceDataMixin = () => ({
  data: () => ({
    simpleMedicalRecordsDataIds: [
      'retinografia',
      'paquimetria',
      'oct-tomografia-de-coerencia-optica-macula',
      'oct-tomografia-de-coerencia-optica-nervo-optico',
      'oct-tomografia-de-coerencia-optica-segmento-anterior',
      'oct-a-tomografia-de-coerencia-optica',
      'tomografia-de-cornea-pentacam',
      'microscopia-especular-de-cornea',
      'indocianinografia-icg',
      'angiofluoresceinografia-afg',
      'usg-ocular',
      'eletrorretinograma-erg-de-campo-total',
      'erg-multifocal',
      'potencial-visual-evocado-pve',
      'pve-varredura',
      'potencial-acuidade-macular',
    ]
  }),
  methods: {
    hasAttendanceValues(attendanceType) {
      if (!this[attendanceType]) return false
      const record = this[attendanceType]?.medical_records?.find(el => { return el.type === this.form.id })

      if (!record) return false
      return Object.keys(record.value).some(key => {
        return !!record.value[key]
      })
    },
    async getAttendanceData(medicalRecords = null, type) {
      if (this.simpleMedicalRecordsDataIds.includes(this.form?.id)) {
        await this.simpleImportData(medicalRecords, type)
        return
      }
      await this.importAttendanceData(medicalRecords, type)
    },
    async simpleImportData(medicalRecords, type) {
      const data = medicalRecords || this[type].medical_records
      const formData = data?.find(el => el.type === this.form.id)

      if (!formData?.id) return

      if (!this.form.showing) {
        this.handleProps({ key: 'showing', value: true })
      }
      const updatePromises = Object.keys(formData.value).map(key => {
          return this.updateForm(`${key}`, formData.value[key])
      })

      await Promise.all(updatePromises)
      await this.updateMedicalRecord(this.form)
    }
  },
  watch: {
    'medicalRecordsStore.changeData': {
      handler() {
        const { medicalRecords, medicalRecordType } = this.medicalRecordsStore
        if (!medicalRecords?.length) return
        this.getAttendanceData(medicalRecords, medicalRecordType)
      },
      deep: true
    }
  }
})

export default attendanceDataMixin